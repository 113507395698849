module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-LWTB56DGVV"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Captain Bob Constructions","short_name":"Captain Bob Constructions","start_url":"/","display":"standalone","background_color":"#000000","theme_color":"#000000","theme_color_in_head":false,"icon":"src/images/logo.png","icons":[{"src":"src/images/favicon/android-icon-36x36.png","sizes":"36x36","type":"image/png"},{"src":"src/images/favicon/android-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"src/images/favicon/android-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"src/images/favicon/android-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"src/images/favicon/android-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"src/images/favicon/android-icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/images/favicon/apple-icon-57x57.png","sizes":"57x57","type":"image/png"},{"src":"src/images/favicon/apple-icon-60x60.png","sizes":"60x60","type":"image/png"},{"src":"src/images/favicon/apple-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"src/images/favicon/apple-icon-76x76.png","sizes":"76x76","type":"image/png"},{"src":"src/images/favicon/apple-icon-114x114.png","sizes":"114x114","type":"image/png"},{"src":"src/images/favicon/apple-icon-120x120.png","sizes":"120x120","type":"image/png"},{"src":"src/images/favicon/apple-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"src/images/favicon/apple-icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"src/images/favicon/apple-icon-180x180.png","sizes":"180x180","type":"image/png"}],"icon_options":{"purpose":"any maskable"},"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"20792f83cc99983bbb49a2553a8b34e7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/captain-bob-website/captain-bob-website/node_modules/gatsby-remark-images","id":"1ea40802-8f8c-53d7-8c7d-52d8db664c7c","name":"gatsby-remark-images","version":"6.19.0","modulePath":"/home/runner/work/captain-bob-website/captain-bob-website/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"linkImagesToOriginal":false,"withWebp":true,"disableBgImage":true,"loading":"eager"},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/captain-bob-website/captain-bob-website","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"linkImagesToOriginal":false,"withWebp":true,"disableBgImage":true,"loading":"eager"},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
